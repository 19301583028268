
import {Component, Mixins, Ref, Watch} from "vue-property-decorator";
import Oran from "@/components/inputs/Oran.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {IExcelDosya, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {VForm} from "@/types";
import {TahsilHarcOranlariniGetirRow} from "@/plugins/uyap-plugin/uyap/TahsilHarcOranlariniGetir";
import {
  HarcMasrafListItemBedelInterface,
  HarcMasrafListItemOranInterface,
  TahsilatNedenleriItem,
  TahsilHarcOranlariDB
} from '@/plugins/uyap-plugin/TahsilHarcOranlariDB';
import TakipRolleri from '@/plugins/uyap-plugin/takip_ac/parser/TakipRolleri';
import {HarcMasrafTypeEnum} from "@/plugins/uyap-plugin/uyap/enum/HarcMasrafTypeEnum";
import {HarcMasrafOdeTaskInterface} from "@/plugins/uyap-plugin/store/modules/TaskHandler/HarcMasrafOdeTaskHandler";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {IAvukatDosya} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

@Component({
  components: {PersonelPicker, DeleteDialog, GenelIslemFormWizard, Tutar, Oran}
})
export default class HarcMasrafOdeFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genel-form') genelForm!: GenelIslemFormWizard;

  harcMasrafTypeEnum = HarcMasrafTypeEnum;
  localValue: GenelIslemEntity = new GenelIslemEntity();
  data: Data = new Data();
  onFly = false;
  valid = false;
  isNullError = false;
  tutar : number  = 0;
  taraf = "";
  yargiGiderTuru = "";
  //@ts-ignore
  harcMasrafTuru :HarcMasrafListItemBedelInterface | HarcMasrafListItemOranInterface = {};
  tahsilatTuru:TahsilatNedenleriItem = new TahsilatNedenleriItem();
  vekaletPulu:any = TahsilHarcOranlariDB.PostaMasraflariList[0];
  tahsilHarcOranlari :TahsilHarcOranlariniGetirRow[] = [];
  odenecekHarcMasraflar : Array<HarcMasrafOdeTaskInterface> = [];

  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "Harç/Masraf Ödemesi Yap",
    "İşlemler Sıraya Alındı"
  ];

  tarafItems = [
    {isim: "Borçlu", id: '22'},
    {isim: "Alacaklı", id: '21'}
  ]

  yargiGiderTuruItems = [
    {isim: "Harç", value: 'H'},
    {isim: "Tahsilat", value: 'T'},
    {isim: "Pul", value: 'D'}
  ]

  harcMasrafHeaders = [
    {text: 'Harç/Masraf', value: 'tur'},
    {text: 'Taraf', value: 'tarafRolAdi'},
    {text: "Miktar", value: "adet"},
    {text: "Tutar", value: "tutar"},
    {text: "Toplam Tutar", value: "toplamTutar"},
    {text: "İşlemler", value: "actions", sortable: false, width: 50},
  ]

  get harcMasrafTuruItems() {
      return TahsilHarcOranlariDB.HarcMasrafList;
  }

  get tahsilatTuruItems(){
    return TahsilHarcOranlariDB.TahsilatNedenleriList;
  }
  get pulItems(){
    return TahsilHarcOranlariDB.PostaMasraflariList;
  }
  getHarcMasrafTuruLabel(item: any) {
    if (item.tur == "H")
      return 'Harç';
    else if (item.tur == "T")
      return 'Tahsilat';
    else if (item.tur == "D")
      return 'Pul';
    else
      return '';
  }

  @Watch('odenecekHarcMasraflar')
  onOdenecekHarcMasraflarChange() {
    this.isNullError = false;
  }

  mounted() {
    this.execTahsilHarcOranlariniGetir();
  }

  async execTahsilHarcOranlariniGetir() {
    let response = await this.$uyap.TahsilHarcOranlariniGetir().run({});
    this.tahsilHarcOranlari = response;
  }

  yargiGiderTuruChange(){
    if(this.yargiGiderTuru == this.harcMasrafTypeEnum.PUL){
      this.data = new Data();
      this.data = {...this.data,...this.vekaletPulu};
      this.data.tutar = this.vekaletPulu.fiyati;
    }
    if(this.yargiGiderTuru == this.harcMasrafTypeEnum.TAHSILAT){
      this.data = new Data();
      this.data = {...this.data,...this.tahsilatTuru};
    }
    console.log("this.data yargiGiderTuruChange",this.data);
  }

  onHarcMasrafTuruChange() {
    console.log("onHarcMasrafTuruChange",this.harcMasrafTuru);
    this.data = {...this.data,...this.harcMasrafTuru};
    console.log("this.data",this.data)
    if (this.data.harcmasrafBedelmiOranmi == "O") {
      this.data.tutarDisabled = false;
      this.data.pesinHarcDisabled = false;
      this.data.tutar = "";
    } else {
      this.data.tutar = this.data.harcMasrafBedel;
      this.data.tutarDisabled = true;
      this.data.pesinHarcDisabled = true;
    }
    this.data.toplamTutar = this.data.tutar;
  }

  onTutarChange() {
    console.log("onTutarChange")
    if (parseFloat(this.data.harcMasrafOran) > 0) {
      console.log(">>");
      let tutar = (parseFloat(this.data.tutar) * (parseFloat(this.data.harcMasrafOran) / 100)).toFixed(2);
      console.log("tutar",tutar);
      this.data.toplamTutar = (parseFloat(tutar)).toFixed(2);
    }else{
      this.data.toplamTutar = (parseFloat(this.data.tutar)).toFixed(2);
    }
    console.log("this.data",this.data)
  }

  onAdd() {
    if(this.data.toplamTutar == ""){
      this.$toast.error("Lütfen Alanları Doldurunuz .");
      return;
    }
    let digerTurVarmi = this.odenecekHarcMasraflar.filter(x => x.tur != this.yargiGiderTuru);
    if (digerTurVarmi.length > 0) {
      this.$toast.warning('Aynı anda farklı yargı gideri türünde ödeme yapamazsınız.')
    } else {
      let tahsilatHarcOrani = 0;
      let tahsilatHarcKayitId = 0;
      let otomatikIliskilendirme = false;
      let id,adi;
      let roller = new TakipRolleri();
      if(this.yargiGiderTuru == this.harcMasrafTypeEnum.HARC_MASRAF){
        id  = this.harcMasrafTuru.harcmasrafKodStr;
        adi = this.harcMasrafTuru.harcMasrafAdi;
      }
      if(this.yargiGiderTuru == this.harcMasrafTypeEnum.TAHSILAT){
        id = this.tahsilatTuru.tahsilatKodu;
        adi = this.tahsilatTuru.tahsilatAciklama;
        tahsilatHarcOrani =  this.data.tahsilHarcOrani.oran;
        tahsilatHarcKayitId =  this.data.tahsilHarcOrani.kayitId;
        otomatikIliskilendirme = this.tahsilatTuru.otomatikIliskilendirme;
      }
      if(this.yargiGiderTuru == this.harcMasrafTypeEnum.PUL) {
        id = this.vekaletPulu.postaMasraflariID;
        adi = this.vekaletPulu.masrafAdi;
        let dahaOncePostaMasrafiEklenmismi = this.odenecekHarcMasraflar.find(x=>x.tur == this.harcMasrafTypeEnum.PUL);
        if (dahaOncePostaMasrafiEklenmismi){
          this.$toast.error("Bu ödeme türünden birden fazla eklenemez...");
          return;
        }
      }

      let itemObject = {
        id: id,
        tur: this.yargiGiderTuru,
        adi: adi,
        adet: this.data.adet,
        tutar: parseFloat(this.data.toplamTutar),
        toplamTutar: parseFloat(this.data.toplamTutar) * this.data.adet,
        tarafRolId: parseInt(this.taraf),
        tarafRolAdi: roller.find(parseInt(this.taraf))?.rolAdi,
        pesinHarcDusulsunMu: this.data.pesinHarcValue,
        pesinHarcTutari: 0,
        tarafBilgileri: null,
        tahsilatAciklama: this.data.aciklama ? this.data.aciklama : '-',
        tahsilatHarcOrani: tahsilatHarcOrani,
        tahsilatKayitId: tahsilatHarcKayitId,
        otomatikIliskilendirme: otomatikIliskilendirme,
      };
      this.odenecekHarcMasraflar.push(itemObject);
      this.data = new Data();
    }
  }

  onDelete(item: any) {
    this.odenecekHarcMasraflar.splice((this.odenecekHarcMasraflar.indexOf(item)), 1);
  }

  createTask() {
    if (this.odenecekHarcMasraflar.length == 0)
      this.isNullError = true
    else if (this.form.validate()) {
      this.onFly = true;
      let tasks: Array<Task> = [];
      // @ts-ignore
      this.localValue.secilen_takipler.forEach((secilenTakip: IAvukatDosya | IExcelDosya) => {
        let task: Task = new Task()
        task.dosya_esas_no = secilenTakip.dosyaNo;
        task.birim_adi = secilenTakip.birimAdi;
        task.dosya_id = secilenTakip.dosyaId;
        task.birim_id = secilenTakip?.birimId;
        task.uyap_dosya_acilis_tarihi = secilenTakip.dosyaAcilisTarihi ? new Date(this.$uyapHelper.formatDate(secilenTakip.dosyaAcilisTarihi)).toString() : "";
        task.task_type_id = TaskTuru.harc_masraf_ode;
        task.data = this.odenecekHarcMasraflar;
        tasks.push(task);
      });
      this.$store.dispatch('postTasks', tasks);
      this.$router.push(ProgramPaths.eicrapro + '/islemler');
    }
  }
}

class Data extends HarcMasrafListItemOranInterface  {
  harcMasrafAdi: string = "";
  harcMasrafKod: string = "";
  harcMasrafKodStr: string = "";
  harcTip: string = "";
  harcMasrafOran: string = "";
  harcMasrafBedel: string = "";
  adet: number = 1;
  tutar: string = "";
  toplamTutar: string = "";
  tutarDisabled: boolean = true;
  pesinHarcDisabled: boolean = true;
  pesinHarcValue: boolean = false;
  aciklama: string = "";
  tahsilHarcOrani!: any;
  tahsilatHarcOrani!: any;
}

