export class HarcMasrafListItemBedelInterface {
    "harcMasrafKod": string = "";
    "harcMasrafAdi": string = "";
    "aciklama": string = "";
    "baslangicHarci": "E" | "H" = "H";
    "harcMasraf": "E" | "H" = "H";
    "harcTip": number | string = "";
    "gv__tabi": number = 0;
    "dv__tabi": number = 0;
    "yg__tabi": number = 0;
    "makbuzTipi": number = 0;
    "harcmasrafKodStr": number | string = "";
    "harcmasrafBedelmiOranmi": "B" | "O" = "B"
    //"harcMasrafOran":any,
    "harcMasrafBedel": string = "";
    "ekranID": number | string = "";
    "pesinHarcDusulsunmu": "E" | "H" = "H";
    "hmDurumu": number | string = "";
    "hmAktif": boolean = false;
    "isSatirDegisecek": boolean = false;
}

export class HarcMasrafListItemOranInterface {
    "harcMasrafKod": string = "";
    "harcMasrafAdi": string = "";
    "aciklama": string = "";
    "baslangicHarci": "E" | "H" = "H";
    "harcMasraf": string = "H";
    "harcTip": number | string = "";
    "gv__tabi": number = 0;
    "dv__tabi": number = 0;
    "yg__tabi": number = 0;
    "makbuzTipi": number = 0;
    "harcmasrafKodStr": number | string = "";
    "harcmasrafBedelmiOranmi": "B" | "O" = "B"
    //"harcMasrafOran":any,
    "harcMasrafOran": any = "";
    "ekranID": number | string = "";
    "pesinHarcDusulsunmu": "E" | "H" = "H";
    "hmDurumu": number | string = "";
    "hmAktif": boolean = false;
    "isSatirDegisecek": boolean = false;
}

export class TahsilatNedenleriItem {
    "tahsilatKodu": number | null = null
    "tahsilatAciklama": string = "";
    "tahsilatHarcOrani": boolean = false;
    "otomatikIliskilendirme": boolean = false;
}


interface TahsilHarcOranlariDBInterface {
    HarcMasrafList: Array<HarcMasrafListItemBedelInterface | HarcMasrafListItemOranInterface>,
    TahsilatNedenleriList: Array<TahsilatNedenleriItem>,
    PostaMasraflariList: Array<{
        "masrafAdi": string,
        "fiyati": number,
        "masrafKodu": number,
        "postaMasraflariID": number,
        "durum": boolean,
        "adet": number,
        "ilkDeger": number,
        "vezneIlkDeger": number,
        "postalamaTuru": number
    }>,
    BigDecimal: number
}

export let TahsilHarcOranlariDB: TahsilHarcOranlariDBInterface = {
    "HarcMasrafList": [{
        "harcMasrafKod": "12805",
        "harcMasrafAdi": "Başvurma Harcı",
        "aciklama": "Takibe Başvurulurken Ödenen Harç Miktarı",
        "baslangicHarci": "E",
        "harcMasraf": "H",
        "harcTip": 0,
        "gv__tabi": 0,
        "dv__tabi": 0,
        "yg__tabi": 0,
        "makbuzTipi": 1,
        "harcmasrafKodStr": "144134",
        "harcmasrafBedelmiOranmi": "B",
        "harcMasrafBedel": "427.6",
        "ekranID": "6",
        "pesinHarcDusulsunmu": "H",
        "hmDurumu": "0",
        "hmAktif": false,
        "isSatirDegisecek": false
    },
        {
            "harcMasrafKod": "12806",
            "harcMasrafAdi": "Cezaevi (Maktu)",
            "aciklama": "Değeri Belli Olmayan İcra Takiplerinde Başvurma Harcının Yarısı Oranında Alınır",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 15,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 0,
            "harcmasrafKodStr": "144135",
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": "213.8",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "13056",
            "harcMasrafAdi": "Cezaevi (Nispi)",
            "aciklama": "Nispi Cezaevi Harcı",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 4,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 0,
            "harcmasrafKodStr": "14851",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": 2,
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "22784",
            "harcMasrafAdi": "Haciz, Teslim ve Satış Harcı",
            "aciklama": "Haciz Teslim ve Satış Harcı",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 27,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "144138",
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": "1004",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12807",
            "harcMasrafAdi": "İflas Başvurma Harcı",
            "aciklama": "Başvurma Harcı",
            "baslangicHarci": "E",
            "harcMasraf": "H",
            "harcTip": 25,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "144136",
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": "704.5",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "13312",
            "harcMasrafAdi": "İflas Defter Tutma Harçları",
            "aciklama": "iflas defter tutma harçları",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 25,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "144141",
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": "656.9",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12808",
            "harcMasrafAdi": "Peşin Harç",
            "aciklama": "Takip Talebi Açılırken Alınır",
            "baslangicHarci": "E",
            "harcMasraf": "H",
            "harcTip": 2,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "14603",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "0.5",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            //@ts-ignore
            "harcMasrafAltSiniri": "0",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12811",
            "harcMasrafAdi": "Suret Harcı",
            "aciklama": "Dosyadan Sureti İstenen Evraklar İçin Alınır",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 14,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "144139",
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": "22.1",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12812",
            "harcMasrafAdi": "Tahliye Harcı (%2.27)",
            "aciklama": "Yıllık Kira Bedeli Üzerinden Alınır",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 3,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "52746",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "2.27",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12813",
            "harcMasrafAdi": "Tahliye Harci(%1.13)",
            "aciklama": "Yıllık Kira Bedeli Üzerinden Alınır",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 3,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "55040",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "1.13",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "21248",
            "harcMasrafAdi": "Tahsil Harcı (% 9.10 )",
            "aciklama": "Hacizden sonra tahsil edilen paralardan alınan harç oranı",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 5,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "52750",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "9.1",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12814",
            "harcMasrafAdi": "Tahsil Harcı (Haricen %2.27)",
            "aciklama": "Tahsil Edilen Bedel Üzerinden Alınır",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 33,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "54272",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "2.27",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12815",
            "harcMasrafAdi": "Tahsil Harcı (Haricen %4.55)",
            "aciklama": "Tahsil Edilen Bedel Üzerinden Alınır",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 33,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "54273",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "4.55",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "15876",
            "harcMasrafAdi": "Tahsil Harcı (Vazgeçme 2.27)",
            "aciklama": "vazgeçmede alınan harç(1,98)",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": 33,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "52751",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "2.27",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "15880",
            "harcMasrafAdi": "Tahsil Harcı (Vazgeçme %4.55)",
            "aciklama": "VAZGEÇME SEBEBİYLE ALINMASI GEREKEN HARÇ",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": "33",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "52752",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "4.55",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "14336",
            "harcMasrafAdi": "Tahsil Harcı (%11.38 Satış Sonrası)",
            "aciklama": "Satış Sonrası Tahsil harcı %9,9",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": "5",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "52749",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "11.38",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "25092",
            "harcMasrafAdi": "Tahsil Harci 492 S.K.nun 1. ST., B,I,3/h bendi geregi % 0,56",
            "aciklama": "Tahsil Harcı 492 S.K.nun 1. ST., B,I,3/h bendi gereği",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": "25",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "72563",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "0.56",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "25093",
            "harcMasrafAdi": "Tahsil Harci 492 S.K.nun 1. ST., B,I,3/h bendi geregi % 1,1375",
            "aciklama": "Tahsil Harcı 492 S.K.nun 1. ST., B,I,3/h bendi gereğİ",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": "25",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "72561",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "1.1375",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "25094",
            "harcMasrafAdi": "Tahsil Harci 492 S.K.nun 1. ST., B,I,3/h bendi gereği % 2,2750",
            "aciklama": "Tahsil Harcı 492 S.K.nun 1. ST., B,I,3/h bendi gereği",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": "25",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "72562",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "2.275",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "25095",
            "harcMasrafAdi": "Tahsil Harci 492 S.K.nun 1. ST., B,I,3/h bendi gereği % 2,845",
            "aciklama": "Tahsil Harcı 492 S.K.nun 1. ST., B,I,3/h bendi gereği",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": "25",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "72560",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "2.845",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "E",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12809",
            "harcMasrafAdi": "Vekalet Suret Harcı",
            "aciklama": "Vekaletname Suret Harcıdır",
            "baslangicHarci": "E",
            "harcMasraf": "H",
            "harcTip": "14",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "144140",
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": "60.8",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "15616",
            "harcMasrafAdi": "Yenilemede Peşin Harç",
            "aciklama": "Yenileme dosyalarında alınması gereken peşin harç",
            "baslangicHarci": "H",
            "harcMasraf": "H",
            "harcTip": "26",
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "19968",
            "harcmasrafBedelmiOranmi": "O",
            "harcMasrafOran": "0.5",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        },
        {
            "harcMasrafKod": "12810",
            "harcMasrafAdi": "Yerine Getirme Harcı",
            "aciklama": "Değeri belli olmayan icra takiplerinde başlangıç harcı olarak, başvurma harcı oranında alınır.",
            "baslangicHarci": "E",
            "harcMasraf": "H",
            "harcTip": 3,
            "gv__tabi": 0,
            "dv__tabi": 0,
            "yg__tabi": 0,
            "makbuzTipi": 1,
            "harcmasrafKodStr": "144137",
            "harcmasrafBedelmiOranmi": "B",
            "harcMasrafBedel": "427.6",
            "ekranID": "6",
            "pesinHarcDusulsunmu": "H",
            "hmDurumu": "0",
            "hmAktif": false,
            "isSatirDegisecek": false
        }
    ],
    "TahsilatNedenleriList": [{
        "tahsilatKodu": 0,
        "tahsilatAciklama": "Borç Tahsilatı",
        "tahsilatHarcOrani": true,
        "otomatikIliskilendirme": true
    },
        {
            "tahsilatKodu": 1,
            "tahsilatAciklama": "Masraf Avansı Tahsilatı",
            "tahsilatHarcOrani": false,
            "otomatikIliskilendirme": false
        },
        {
            "tahsilatKodu": 2,
            "tahsilatAciklama": "Diğer Teminat Bedeli Tahsilatı",
            "tahsilatHarcOrani": true,
            "otomatikIliskilendirme": false
        },
        {
            "tahsilatKodu": 3,
            "tahsilatAciklama": "Satış Bedeli Tahsilatı",
            "tahsilatHarcOrani": true,
            "otomatikIliskilendirme": false
        },
        {
            "tahsilatKodu": 5,
            "tahsilatAciklama": "Bilirkişi Tahsilatı",
            "tahsilatHarcOrani": false,
            "otomatikIliskilendirme": false
        },
        {
            "tahsilatKodu": 8,
            "tahsilatAciklama": "İhale Teminat Bedeli Tahsılatı",
            "tahsilatHarcOrani": true,
            "otomatikIliskilendirme": false
        },
        {
            "tahsilatKodu": 10,
            "tahsilatAciklama": "Masraf Avansı (Taşıt Ücreti) Tahsilatı",
            "tahsilatHarcOrani": false,
            "otomatikIliskilendirme": false
        },
        {
            "tahsilatKodu": 11,
            "tahsilatAciklama": "Masraf Avansı (Satış Avansı) Tahsilatı",
            "tahsilatHarcOrani": false,
            "otomatikIliskilendirme": false
        }
    ],
    "PostaMasraflariList": [{
        "masrafAdi": "Vekalet Pulu",
        "fiyati": 96,
        "masrafKodu": 0,
        "postaMasraflariID": 4000,
        "durum": false,
        "adet": 1,
        "ilkDeger": 0,
        "vezneIlkDeger": 0,
        "postalamaTuru": 0
    }],
    "BigDecimal": 0.01
};